import React, {useState, useEffect} from 'react';
import {
  TextField,
  Button,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Grid,
  IconButton,
  Chip
} from '@mui/material';
import FarmDAO from '../../dao/FarmDAO';
import YouTubeIcon from '@mui/icons-material/YouTube';

const CherrySearcher = ({isDisabled, onPlayClip}) => {
  const [searchText, setSearchText] = useState('');
  const [results, setResults] = useState([]);

  useEffect(() => {
    const lastSearchText = localStorage.getItem('lastSearchText');
    if (lastSearchText) {
      setSearchText(lastSearchText);
      handleSearch(lastSearchText);
    }
  }, []);

  const handleSearch = async (text) => {
    await setResults([]);

    const searchQuery = text || searchText;
    localStorage.setItem('lastSearchText', searchQuery);

    const storedFarms = FarmDAO.getFarms(); // FarmDAO 사용
    const searchResults = [];

    storedFarms.forEach(farm => {
      farm.timeScripts.forEach(ts => {
        if (ts.script.includes(searchQuery)) {
          searchResults.push({
            farm: farm,
            time: ts.time,
            matchedScript: ts.script
          });
        }
      });
    });

    await setResults(searchResults);
  };

  const handlePlayClip = (farm, time, matchedScript) => {
    onPlayClip(farm, time, matchedScript);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const highlightText = (text, highlight) => {
    const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
    return (
      <span>
        {parts.map((part, index) =>
          part.toLowerCase() === highlight.toLowerCase() ? (
            <span key={index} style={{ backgroundColor: 'yellow' }}>{part}</span>
          ) : (
            part
          )
        )}
      </span>
    );
  };

  return (
    <Box>
      <Grid container spacing={2} sx={{mb: 2}} alignItems="center" justifyContent="center">
        <Grid item xs sm={8} md>
          <TextField
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            onKeyPress={handleKeyPress}
            fullWidth
            size="small"
            InputProps={{
              style: {
                backgroundColor: isDisabled ? '#e0e0e0' : 'white',
                cursor: isDisabled ? 'not-allowed' : 'text'
              }
            }}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs="auto" sm={4} md="auto">
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleSearch()}
            sx={{width: {xs: '100%', sm: 'auto', md: '70px'}, height: '100%'}}
            disabled={isDisabled}
          >
            검색
          </Button>
        </Grid>
      </Grid>
      {results.length > 0 && (
        <Box sx={{mt: 2}}>
          <TableContainer component={Paper} sx={{maxHeight: 360, overflowY: 'auto'}}>
            <Table sx={{tableLayout: 'fixed'}}>
              <TableHead style={{position: 'sticky', top: 0, backgroundColor: '#d3d3d3', zIndex: 1}}>
                <TableRow style={{backgroundColor: '#F9F9F9'}}>
                  <TableCell
                    style={{width: '50%', fontWeight: 'bold', textAlign: 'center', padding: '8px 8px'}}>이름</TableCell>
                  <TableCell style={{width: '50%', fontWeight: 'bold', textAlign: 'center', padding: '8px 8px'}}>상세
                    정보</TableCell>
                  <TableCell
                    style={{width: '60px', fontWeight: 'bold', textAlign: 'center', padding: '8px 8px'}}>액션</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {results.map((result) => (
                  <TableRow key={result.farm.id}>
                    <TableCell style={{
                      padding: '4px 8px',
                      fontSize: '0.875rem',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap'
                    }}>{result.farm.name}</TableCell>
                    <TableCell style={{
                      padding: '4px 8px',
                      fontSize: '0.875rem',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap'
                    }}>
                      <Box>
                        <ul style={{listStyleType: 'none', paddingLeft: 0}}>
                          <li style={{display: 'flex', alignItems: 'center', marginBottom: '6px'}}>
                            <Chip label="Script" sx={{
                              width: '56px',
                              borderRadius: '2px',
                              height: 'auto',
                              padding: '0px 0px',
                              mr: 1,
                              fontSize: '0.75rem',
                              fontWeight: 'bold'
                            }}/>
                            <Typography variant="body2" sx={{color: 'text.secondary'}}>
                              <Box component="span"
                                   sx={{fontWeight: 'bold'}}>{result.time}</Box> {highlightText(result.matchedScript, searchText)}
                            </Typography>
                          </li>
                          <li style={{display: 'flex', alignItems: 'center', marginBottom: '6px'}}>
                            <Chip label="Link" sx={{
                              width: '56px',
                              borderRadius: '2px',
                              height: 'auto',
                              padding: '0px 0px',
                              mr: 1,
                              fontSize: '0.75rem',
                              fontWeight: 'bold'
                            }}/>
                            <Typography variant="body2"><a href={result.farm.url} target="_blank"
                                                           rel="noopener noreferrer">{result.farm.url}</a></Typography>
                          </li>
                        </ul>
                      </Box>
                    </TableCell>
                    <TableCell sx={{width: '60px', padding: '4px 8px', textAlign: 'center', fontSize: '0.875rem'}}>
                      <IconButton color="red"
                                  onClick={() => handlePlayClip(result.farm, result.time, result.matchedScript)}>
                        <YouTubeIcon/>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </Box>
  );
};

export default CherrySearcher;