import React, { useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, IconButton, TextField, Box, InputAdornment, Typography, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FacebookIcon from '@mui/icons-material/Facebook';
import { toast } from 'react-toastify';
import CodeIcon from '@mui/icons-material/Code';
import 'react-toastify/dist/ReactToastify.css';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import blogPrompt from './BlogPrompt';
import {getCherryData} from "../../utils/cherryUtils";
dayjs.extend(duration);

const ShareCherryPickDialog = ({ cherry, playerUrl, startOffset, endOffset, open, handleClose }) => {
  useEffect(() => {
    if (!window.Kakao.isInitialized()) {
      window.Kakao.init('2a58f59947c5ae9707bb9a4b48d9d7ea'); // Replace with your Kakao JavaScript key
    }
  }, []);

  const handleCopy = () => {
    navigator.clipboard.writeText(playerUrl);
    // const currentDomain = window.location.origin;
    // const fullPlayerUrl = `${currentDomain}/cherry?cherryUrl=${encodeURIComponent(playerUrl)}`;
    // navigator.clipboard.writeText(fullPlayerUrl);
    alert('URL이 복사되었습니다.');
  };

  const handleKakaoShare = () => {
    const currentDomain = window.location.origin;
    const fullPlayerUrl = `${currentDomain}/cherry?cherryUrl=${encodeURIComponent(playerUrl)}`;
    const imageUrl = `https://fakeimg.pl/600x400/000000/ffffff/?text=${encodeURIComponent(cherry.name)}&font=noto&font_size=24`;

    window.Kakao.Link.sendDefault({
      objectType: 'feed',
      content: {
        title: '체리픽에서 보낸 영상입니다.',
        description: `${cherry.note}`,
        imageUrl: imageUrl,
        link: {
          mobileWebUrl: fullPlayerUrl,
          webUrl: fullPlayerUrl,
        },
      },
      buttons: [
        {
          title: '동영상 보기',
          link: {
            mobileWebUrl: fullPlayerUrl,
            webUrl: fullPlayerUrl,
          },
        }
      ],
    });
  };

  const handleFacebookShare = () => {
    const facebookUrl = `https://www.facebook.com/dialog/share?display=popup&href=${encodeURIComponent(playerUrl)}}`;
    window.open(facebookUrl, '_blank');
  };

  const handleHtmlShare = async () => {
    try {
      blogPrompt.cherryData = getCherryData(cherry, startOffset, endOffset);
      navigator.clipboard.writeText(JSON.stringify(blogPrompt));
      toast.success("HTML 코드 생성 프롬프트가 클립보드에 복사되었습니다. AI에 질의하여 코드를 완성하세요.");
    } catch (error) {
      console.error('Error generating prompt:', error);
      toast.error("프롬프트 생성 중 오류가 발생했습니다.");
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ fontSize: '1.25rem' }}>
        체리픽 공유
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Typography variant="body2" gutterBottom sx={{ color: 'text.secondary' }}>
          영상의 핵심만 똑똑하게 공유하세요. 당신이 선택한 특정 구간만을 공유할 수 있습니다.
        </Typography>
        <Box display="flex" alignItems="center" justifyContent="center" mb={2}>
          <Button onClick={handleKakaoShare} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Box
              component="img"
              src="https://developers.kakao.com/assets/img/about/logos/kakaolink/kakaolink_btn_medium.png"
              alt="카카오톡"
              sx={{ width: 40, height: 40, borderRadius: '50%', mb: 0.5 }}
            />
            <Typography variant="caption">카카오톡</Typography>
          </Button>
          <Button onClick={handleFacebookShare} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <FacebookIcon sx={{ width: 40, height: 40, mb: 0.5 }} />
            <Typography variant="caption">페이스북</Typography>
          </Button>
          <Button onClick={handleHtmlShare} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <CodeIcon sx={{ width: 40, height: 40, mb: 0.5 }} />
            <Typography variant="caption">HTML</Typography>
          </Button>
        </Box>
        <Box display="flex" alignItems="center">
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            value={playerUrl}
            InputProps={{
              readOnly: true,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleCopy} edge="end">
                    <ContentCopyIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ShareCherryPickDialog;
