import React from 'react';
import { Box, Typography, Button, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const AddCherryStorageGuide = ({ storages, boxes, cherries, onAddStorage, onAddBox }) => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        backgroundColor: '#fff3cd',
        padding: '20px',
        borderRadius: '8px',
        textAlign: 'center',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        margin: '20px 0'
      }}
    >
      <Typography variant="h5" gutterBottom>
        👨‍🌾 PICK 보관하기
      </Typography>
      <Typography variant="body2" paragraph>
        체리 PICK을 보관하면 언제든 다시 찾아볼 수 있습니다. 체리를 보관할 STORAGE, BOX 를 만들어 두세요.
      </Typography>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              backgroundColor: '#ffffff',
              padding: '10px',
              borderRadius: '8px',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
              textAlign: 'center',
              margin: '10px',
            }}
          >
            <Box
              sx={{
                backgroundColor: '#555',
                color: '#ffffff',
                borderRadius: '50%',
                width: '30px',
                height: '30px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '0 auto 10px',
                fontSize: '1.4rem',
                fontWeight: 'bolder',
                fontFamily: 'cursive',
                fontStyle: 'italic'
              }}
            >
              1
            </Box>
            <Typography variant="body1">'첫 번째 STORAGE 등록'버튼을 클릭하세요. 이름을 입력하고 스토리지를 생성하세요. </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={onAddStorage}
              disabled={storages.length > 0}
              sx={{ mt: 2 }}
            >
              🏠 첫 번째 STORAGE 등록
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              backgroundColor: '#ffffff',
              padding: '10px',
              borderRadius: '8px',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
              textAlign: 'center',
              margin: '10px',
            }}
          >
            <Box
              sx={{
                backgroundColor: '#555',
                color: '#ffffff',
                borderRadius: '50%',
                width: '30px',
                height: '30px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '0 auto 10px',
                fontSize: '1.4rem',
                fontWeight: 'bolder',
                fontFamily: 'cursive',
                fontStyle: 'italic'
              }}
            >
              2
            </Box>
            <Typography variant="body1">'첫 번째 BOX 등록'버튼을 클릭하세요. 이름을 입력하고 박스를 생성하세요. </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={onAddBox}
              disabled={boxes.length > 0}
              sx={{ mt: 2 }}
            >
              📦 첫 번째 BOX 등록
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              backgroundColor: '#ffffff',
              padding: '10px',
              borderRadius: '8px',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
              textAlign: 'center',
              margin: '10px',
            }}
          >
            <Box
              sx={{
                backgroundColor: '#555',
                color: '#ffffff',
                borderRadius: '50%',
                width: '30px',
                height: '30px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '0 auto 10px',
                fontSize: '1.4rem',
                fontWeight: 'bolder',
                fontFamily: 'cursive',
                fontStyle: 'italic'
              }}
            >
              3
            </Box>
            <Typography variant="body1">동영상 플레이어에서 체리픽 버튼를 클릭하고 원하는 STORAGE, BOX 에 담아 두세요. <br /></Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate('/')}
              disabled={cherries.length > 0}
              sx={{ mt: 2 }}
            >
              🍒 PICK 메뉴로 이동
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddCherryStorageGuide;