import React from 'react';
import { Box, Typography, Grid, Button, useMediaQuery } from '@mui/material';

const AddCherryFarmGuide = ({ triggerAddFarmClick }) => {
  const isMediumScreen = useMediaQuery(theme => theme.breakpoints.between('sm', 'md'));

  return (
    <Box
      sx={{
        backgroundColor: '#fff3cd',
        padding: '20px',
        borderRadius: '8px',
        textAlign: 'center',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        margin: '20px 0'
      }}
    >
      <Typography variant="h5" gutterBottom>
        👨‍🌾 FARM 등록하기: 체리 PICK의 첫 단계
      </Typography>
      <Typography variant="body2" paragraph>
        체리 농장(FARM)은 여러분이 PICK하고 싶은 유튜브 콘텐츠를 등록하는 곳입니다. 관심 있는 동영상을 자막과 함께 쉽게 등록해보세요.
      </Typography>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              backgroundColor: '#ffffff',
              padding: '10px',
              borderRadius: '8px',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
              textAlign: 'center',
              margin: '10px',
              height: '120px' // Uniform height for all boxes
            }}
          >
            <Box
              sx={{
                backgroundColor: '#555',
                color: '#ffffff',
                borderRadius: '50%',
                width: '30px',
                height: '30px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '0 auto 10px',
                fontSize: '1.4rem',
                fontWeight: 'bolder',
                fontFamily: 'cursive',
                fontStyle: 'italic'
              }}
            >
              1
            </Box>
            <Typography variant="body1" sx={{ wordBreak: isMediumScreen ? 'break-word' : 'normal' }}>
              '🚜 첫 번째 FARM 등록' 버튼을 클릭하세요. 유튜브 URL과 영상 제목을 입력하세요.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              backgroundColor: '#ffffff',
              padding: '10px',
              borderRadius: '8px',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
              textAlign: 'center',
              margin: '10px',
              height: '120px' // Uniform height for all boxes
            }}
          >
            <Box
              sx={{
                backgroundColor: '#555',
                color: '#ffffff',
                borderRadius: '50%',
                width: '30px',
                height: '30px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '0 auto 10px',
                fontSize: '1.4rem',
                fontWeight: 'bolder',
                fontFamily: 'cursive',
                fontStyle: 'italic'
              }}
            >
              2
            </Box>
            <Typography variant="body1">유튜브 동영상 설명에서 '...더보기'를 클릭한 후, '스크립트 표시'를 눌러 자막을 확인합니다. </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              backgroundColor: '#ffffff',
              padding: '10px',
              borderRadius: '8px',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
              textAlign: 'center',
              margin: '10px',
              height: '120px'
            }}
          >
            <Box
              sx={{
                backgroundColor: '#555',
                color: '#ffffff',
                borderRadius: '50%',
                width: '30px',
                height: '30px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '0 auto 10px',
                fontSize: '1.4rem',
                fontWeight: 'bolder',
                fontFamily: 'cursive',
                fontStyle: 'italic'
              }}
            >
              3
            </Box>
            <Typography variant="body1"> 전체 스크립트를 복사(Ctrl+C)합니다. 복사한 스크립트를 붙여넣기(Ctrl+V)하고 영상을 등록합니다.</Typography>
          </Box>
        </Grid>
      </Grid>
      <Button
        variant="contained"
        color="primary"
        onClick={triggerAddFarmClick}
        sx={{ mt: 2 }}
      >
        🚜 첫 번째 FARM 등록
      </Button>
    </Box>
  );
};

export default AddCherryFarmGuide;