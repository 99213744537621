// src/dao/BoxDAO.js
import CherryDAO from './CherryDAO';
import StorageDAO from './StorageDAO';

class BoxDAO {
  static getBoxes() {
    return JSON.parse(localStorage.getItem('cherryBoxes')) || [];
  }

  static getBoxesByStorage(storageName) {
    const boxes = this.getBoxes();
    return boxes.filter(box => box.storage === storageName);
  }

  static addBox(boxName, storageName) {
    const boxes = this.getBoxes();
    const storages = StorageDAO.getStorages();

    // Check if storage exists
    if (!storages.includes(storageName)) {
      throw new Error('Storage does not exist');
    }

    // Check for duplicate box name
    if (boxes.some(box => box.name === boxName)) {
      throw new Error('Box name already exists');
    }

    const box = { name: boxName, storage: storageName };
    boxes.push(box);
    localStorage.setItem('cherryBoxes', JSON.stringify(boxes));
  }

  static deleteBox(boxName, deleteAll = false) {
    const boxes = this.getBoxes();
    if (deleteAll) {
      const box = boxes.find(box => box.name === boxName);
      if (box) {
        if (box) {
          const cherries = CherryDAO.getCherriesByStorageAndBox(box.storage, box.name);
          cherries.forEach(cherry => CherryDAO.deleteCherry(cherry.id));
        }
      }
    }

    const updatedBoxes = boxes.filter(box => box.name !== boxName);
    localStorage.setItem('cherryBoxes', JSON.stringify(updatedBoxes));
  }
}

export default BoxDAO;