import React, { useState, useEffect } from 'react';
import { Box, Typography, Card, CardContent, Grid, Divider } from '@mui/material';
import CherrySearcher from '../components/pick/CherrySearcher';
import CherryPlayer from '../components/common/CherryPlayer';
import MoveCherryFarmGuide from '../components/pick/MoveCherryFarmGuide';
import FarmDAO from '../dao/FarmDAO';

const CherryPick = () => {
  const [clipData, setClipData] = useState(null);
  const [farms, setFarms] = useState([]);
  const [showNote, setShowNote] = useState(false);

  useEffect(() => {
    const fetchedFarms = FarmDAO.getFarms();
    setFarms(fetchedFarms);
  }, []);

  const handlePlayClip = (farm, time, matchedScript) => {
    setClipData({ farm, time, matchedScript });
  };

  return (
    <Box sx={{ pt: 2 }}>
      <CherrySearcher onPlayClip={handlePlayClip} isDisabled={farms.length === 0} />
      {farms.length === 0 && <MoveCherryFarmGuide />}
      <Box mt={2} />
      {clipData && <CherryPlayer cherry={clipData} showNote={showNote} />}

      <Typography variant="h6" gutterBottom align="center" sx={{ mt: 4 }}> Tips </Typography>
      <Divider sx={{ borderStyle: 'dotted', borderColor: 'grey.400', mb: 2 }} />
      <Typography variant="body2" align="center" style={{ fontSize: '0.875rem', color: '#808080', marginBottom: '16px' }}>
        체리로 할 수 있는 일을 상상해 보세요. 무엇을 PICK 하든 👌!!! 여기 몇 가지 예시가 있습니다.
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <Card sx={{ height: '100%' }}>
            <CardContent>
              <Typography variant="h6" gutterBottom align="center">
                어학기
              </Typography>
              <Typography variant="body1" paragraph align="center" sx={{ color: 'primary.main', fontWeight: 'bold' }}>
                여행 계획이 있으신가요?
              </Typography>
              <Typography variant="h1" paragraph align="center">🎧</Typography>
              <Typography variant="body2" paragraph align="center" sx={{ color: 'text.secondary' }}>
                유튜브에서 꼭 필요한 구문을 PICK하여 BOX에 저장하고 언제든 반복 학습하세요. '안녕하세요', '감사합니다'와 같은 필수 표현을 체리로 모아두면 귀로 듣고 눈으로 보고 쉽게 기억할 수 있습니다.
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Card sx={{ height: '100%' }}>
            <CardContent>
              <Typography variant="h6" gutterBottom align="center">
                영상 노트
              </Typography>
              <Typography variant="body1" paragraph align="center" sx={{ color: 'primary.main', fontWeight: 'bold' }}>
                강의 영상에서 중요한 포인트만
              </Typography>
              <Typography variant="h1" paragraph align="center">📝</Typography>
              <Typography variant="body2" paragraph align="center" sx={{ color: 'text.secondary' }}>
                유튜브 강의에서 핵심 내용을 PICK하고, BOX에 체리를 저장해두세요. 시험 전 복습이 필요할 때, 저장된 체리를 한 번에 볼 수 있어 공부 효율이 쑥쑥 올라갑니다.
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Card sx={{ height: '100%' }}>
            <CardContent>
              <Typography variant="h6" gutterBottom align="center">
                댄스 교실
              </Typography>
              <Typography variant="body1" paragraph align="center" sx={{ color: 'primary.main', fontWeight: 'bold' }}>
                좋아하는 안무, 이제 놓치지 마세요.
              </Typography>
              <Typography variant="h1" paragraph align="center">💃</Typography>
              <Typography variant="body2" paragraph align="center" sx={{ color: 'text.secondary' }}>
                유튜브 댄스 영상에서 내가 배우고 싶은 동작을 PICK하여 저장하세요. BOX에 담긴 체리로 안무의 중요한 부분만 반복해서 연습할 수 있습니다. 언제든 플레이하며 원하는 동작을 완벽하게 익히세요.
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Card sx={{ height: '100%' }}>
            <CardContent>
              <Typography variant="h6" gutterBottom align="center">
                발표 자료 준비
              </Typography>
              <Typography variant="body1" paragraph align="center" sx={{ color: 'primary.main', fontWeight: 'bold' }}>
                발표 준비, 체리로 핵심만 빠르게
              </Typography>
              <Typography variant="h1" paragraph align="center">🗣️</Typography>
              <Typography variant="body2" paragraph align="center" sx={{ color: 'text.secondary' }}>
                유튜브에서 발표 주제와 관련된 자료를 PICK하여 BOX에 저장하세요. 중요한 통계, 인용구, 설명 영상을 체리로 모아두면 발표 준비가 훨씬 효율적입니다. 필요한 정보를 빠르게 재생하며 발표 내용을 완벽하게 준비할 수 있습니다.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CherryPick;
