import React, {useState, useEffect, useCallback} from 'react';
import {Card, CardContent, CardHeader, TextField, Typography, Box, Chip, Button, IconButton} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CherryPickDialog from '../pick/CherryPickDialog';
import ShareCherryPickDialog from './ShareCherryPickDialog';
import YouTubeIcon from '@mui/icons-material/YouTube';
import ShareIcon from '@mui/icons-material/Share';
import {findMatchedScript} from '../../utils/scriptUtils';
import {getClipUrl} from "../../utils/cherryUtils";
import CherryDAO from "../../dao/CherryDAO";

const DEFAULT_BEFORE_SEC = 5;
const DEFAULT_AFTER_SEC = 5;
const MAX_SEC = 60;

const CherryPlayer = ({cherry, showNote, onCherryUpdate}) => {
  const [beforeSec, setBeforeSec] = useState(DEFAULT_BEFORE_SEC);
  const [afterSec, setAfterSec] = useState(DEFAULT_AFTER_SEC);
  const [playUrl, setPlayUrl] = useState('');
  const [playScript, setPlayScript] = useState([]);
  const [pickOpen, setPickOpen] = useState(false);
  const [shareOpen, setShareOpen] = useState(false);
  const [isEditingNote, setIsEditingNote] = useState(false);
  const [noteContent, setNoteContent] = useState(cherry.note || '');

  const initializeTimes = (cherry) => {
    setBeforeSec(cherry.startTime !== undefined ? cherry.startTime : DEFAULT_BEFORE_SEC);
    setAfterSec(cherry.endTime !== undefined ? cherry.endTime : DEFAULT_AFTER_SEC);
  };

  const playCherry = useCallback((cherry, beforeSec, afterSec) => {
    // const [minutes, seconds] = cherry.time.split(':').map(Number);
    // const pointTime = minutes * 60 + seconds;
    // const clipStart = Math.max(pointTime - beforeSec, 0);
    // const clipEnd = pointTime + afterSec;
    //
    // if (!cherry.farm) return [{time: null, script: '해당 시구간에 일치하는 스크립트가 없습니다.'}];

    try {
      // const url = new URL(cherry.farm.url);
      // const urlParams = new URLSearchParams(url.search);
      // const videoId = urlParams.get('v');
      // if (!videoId) {
      //   alert('유효한 YouTube URL을 입력하세요.');
      //   return;
      // }
      //
      // const newPlayerUrl = `https://www.youtube.com/embed/${videoId}?start=${clipStart}&end=${clipEnd}&autoplay=1&controls=1`;

      const clipUrl = getClipUrl(cherry, beforeSec, afterSec);
      console.log(clipUrl);
      setPlayUrl(clipUrl);
    } catch (error) {
      alert('유효한 URL을 입력하세요.');
    }
  }, [cherry]);

  useEffect(() => {
    if (cherry) {
      initializeTimes(cherry);
      playCherry(cherry, cherry.startTime || beforeSec, cherry.endTime || afterSec);
      const scriptData = findMatchedScript(cherry.farm, cherry.time, cherry.startTime || beforeSec, cherry.endTime || afterSec);
      setPlayScript(scriptData);
      setNoteContent(cherry.note || '');
    }
  }, [cherry]);

  const handlePlayClip = () => {
    if (cherry) {
      setPlayUrl(''); // URL을 빈 문자열로 설정하여 변경된 것으로 인식하게 함
      setTimeout(() => {
        playCherry(cherry, beforeSec, afterSec);
        const scriptData = findMatchedScript(cherry.farm, cherry.time, beforeSec, afterSec);
        setPlayScript(scriptData);
      }, 100); // 짧은 지연 시간 후에 다시 URL 설정
    } else {
      alert('cherry가 없습니다.');
    }
  };

  const handleCherryPick = () => {
    setPickOpen(false); // Close the dialog if it's already open
    setTimeout(() => {
      setPickOpen(true); // Open the dialog after a short delay to reset its state
    }, 100);
  };

  const handlePickClose = () => {
    setPickOpen(false);
    if (onCherryUpdate) {
      onCherryUpdate(); // 체리픽 실행 후 업데이트 호출
    }
  };

  const handleShareOpen = () => {
    setShareOpen(true);
  };

  const handleShareClose = () => {
    setShareOpen(false);
  };

  const handleNoteEdit = () => {
    setIsEditingNote(true);
  };

  const handleNoteBlur = () => {
    setIsEditingNote(false);
    CherryDAO.updateCherryNoteById(cherry.id, noteContent);
    if (onCherryUpdate) {
      onCherryUpdate();
    }
  };

  return (
    <Card sx={{
      backgroundColor: 'white',
      borderRadius: '8px',
      boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
      position: 'relative'
    }}>
      {cherry && (
        <CardHeader
          title={cherry.farm.name}
          sx={{backgroundColor: '#F9F9F9', height: '12px'}}
          titleTypographyProps={{variant: 'body2', fontWeight: 'bold', textAlign: 'center'}}
        />
      )}
      <CardContent>
        {cherry && (
          <Box mb={2}>
            <Box display="flex" flexDirection={{xs: 'column', md: 'row'}} alignItems="center" mt={2}>
              <Box display="flex" mb={{xs: 0.5, md: 0}}>
                <Typography variant="body2">
                  {`기준 시간 ${cherry.time} `}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" mb={{xs: 2, md: 0}}>
                <Typography variant="body2">
                  &nbsp; 이전
                </Typography>
                <TextField
                  type="number"
                  value={beforeSec}
                  onChange={(e) => setBeforeSec(Math.min(Math.max(e.target.value, 0), MAX_SEC))}
                  size="small"
                  inputProps={{min: 0, max: MAX_SEC}}
                  InputProps={{style: {height: '30px'}}}
                  style={{width: '60px', margin: '0 10px'}}
                />
                <Typography variant="body2">
                  초부터 &nbsp;
                </Typography>
                <Typography variant="body2">
                  {`이후 `}
                </Typography>
                <TextField
                  type="number"
                  value={afterSec}
                  onChange={(e) => setAfterSec(Math.min(Math.max(e.target.value, 0), MAX_SEC))}
                  size="small"
                  inputProps={{min: 0, max: MAX_SEC}}
                  InputProps={{style: {height: '30px'}}}
                  style={{width: '60px', margin: '0 10px'}}
                />
                <Typography variant="body2" sx={{marginRight: '30px'}}>
                  초까지 재생
                </Typography>
              </Box>
              <Box display="flex" alignItems="center">
                <Button variant="outlined" color="secondary" onClick={handlePlayClip}
                        sx={{height: '30px', marginRight: '10px'}}>
                  <YouTubeIcon sx={{marginRight: '5px'}}/>
                  재생
                </Button>
                <Button variant="outlined" color="secondary" onClick={handleCherryPick}
                        sx={{height: '30px', marginRight: '10px'}}>
                  🍒 체리픽
                </Button>
                <Button variant="outlined" color="secondary" onClick={handleShareOpen} sx={{height: '30px'}}>
                  <ShareIcon sx={{marginRight: '5px', fontSize: '20px'}}/>
                  공유
                </Button>
              </Box>
            </Box>
          </Box>
        )}
        <Box display="flex" flexDirection={{xs: 'column', md: 'row'}} mt={2} width="100%">
          {playUrl && (
            <Box flexGrow={1} sx={{
              width: '100%',
              height: 0,
              position: 'relative',
              paddingBottom: {xs: '56.25%', md: '38.0%', lg: '41.5%'},
              borderRadius: '8px', // 16:9 aspect ratio
              overflow: 'hidden',
              '& iframe': {
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%'
              }
            }}>
              <iframe
                title="Cherry Player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                src={playUrl}
              ></iframe>
            </Box>
          )}
          {cherry && (
            <Box sx={{
              width: {xs: '100%', md: '372px'},
              maxHeight: {md: '312px', lg: '465px'},
              overflowY: 'auto',
              flexGrow: 1,
              ml: {md: 2},
              mt: {xs: 2, md: 0},
              border: '1px solid #E0E0E0', // Border color same as table header and column separator lines
              borderRadius: '8px'
            }}>
              <Box sx={{padding: '10px'}}>
                <Typography variant="subtitle1" sx={{mb: 2, fontWeight: 'bold'}}>스크립트</Typography>
                {playScript.map((item, index) => (
                  <Box key={index} display="flex" alignItems="center" mb={1}>
                    {item.time &&
                      <Chip label={<Typography variant="caption" sx={{
                        fontWeight: 'bold',
                        padding: 0,
                        color: '#4682B4'
                      }}>{item.time}</Typography>}
                            color="default" sx={{
                        width: 'auto',
                        borderRadius: '2px',
                        height: '18px',
                        padding: 0,
                        mr: 1,
                        fontSize: '0.75rem',
                        fontWeight: 'bold',
                        '& .MuiChip-label': {
                          padding: 1
                        }
                      }}
                      />
                    }
                    <Typography variant="caption">{item.script}</Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          )}
        </Box>

        {showNote && (
          <Box mt={2} p={2} sx={{
            backgroundColor: '#F9F9F9', borderRadius: '8px', border: '1px solid #E0E0E0', // Border color same as table header and column separator lines
            position: 'relative'
          }}>
            <Typography variant="subtitle1" sx={{mb: 2, fontWeight: 'bold'}}>노트</Typography>
            {isEditingNote ? (
              <TextField
                fullWidth
                multiline
                rows={5}
                value={noteContent}
                onChange={(e) => setNoteContent(e.target.value)}
                onBlur={handleNoteBlur}
                autoFocus
              />
            ) : (
              <Typography variant="body2">{noteContent}</Typography>
            )}
            <IconButton
              onClick={handleNoteEdit}
              sx={{position: 'absolute', top: 8, right: 8}}
            >
              <EditIcon/>
            </IconButton>
          </Box>
        )}

        <CherryPickDialog open={pickOpen} handleClose={handlePickClose} clipData={cherry} startTime={beforeSec}
                          endTime={afterSec}/>
        <ShareCherryPickDialog open={shareOpen} handleClose={handleShareClose} cherry={cherry} playerUrl={playUrl} startOffset={beforeSec}
                               endOffset={afterSec}/>
      </CardContent>
    </Card>
  );
};

export default CherryPlayer;
