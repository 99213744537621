import React, { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Typography,
  IconButton
} from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";

const AddCherryStorageDialog = ({ open, onClose, onSave }) => {
  const [storageName, setStorageName] = useState('');

  const handleSave = () => {
    onSave(storageName);
    setStorageName('');
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ fontSize: '1.25rem' }}>
        체리 스토리지 추가
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Typography>스토리지를 추가 합니다. 스토리지명을 입력하고 저장 버튼을 누르세요.</Typography>
        <TextField
          autoFocus
          margin="normal"
          type="text"
          size={'small'}
          fullWidth
          value={storageName}
          onChange={(e) => setStorageName(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onClose}>취소</Button>
        <Button color="primary" onClick={handleSave} style={{ marginLeft: '10px' }}>생성</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddCherryStorageDialog;