// src/components/DeleteCherryStorageDialog.js
import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';

const DeleteCherryStorageDialog = ({ open, onClose, onConfirm, selectedStorage }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Delete Cherry Storage</DialogTitle>
      <DialogContent>
        <DialogContentText>
          체리 스토리지("{selectedStorage}")를 삭제하면 보관중인 체리 박스를 잃을 수 있습니다. 그래도 삭제하시겠습니까?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">취소</Button>
        <Button onClick={onConfirm} color="secondary">확인</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteCherryStorageDialog;