// src/dao/StorageDAO.js
import BoxDAO from './BoxDAO';

class StorageDAO {
  static getStorages() {
    return JSON.parse(localStorage.getItem('cherryStorages')) || [];
  }

  static addStorage(storage) {
    const storages = this.getStorages();
    if (storages.includes(storage)) {
      throw new Error('Storage name already exists');
    }
    storages.push(storage);
    localStorage.setItem('cherryStorages', JSON.stringify(storages));
  }

  static deleteStorage(storage, deleteAll = false) {
    const storages = this.getStorages().filter(s => s !== storage);
    localStorage.setItem('cherryStorages', JSON.stringify(storages));

    if (deleteAll) {
      const boxes = BoxDAO.getBoxes().filter(box => box.storage === storage);
      boxes.forEach(box => {
        BoxDAO.deleteBox(box.name, true);
      });
    }
  }
}

export default StorageDAO;