import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, FormControl, Select, MenuItem, Typography } from '@mui/material';

const MoveBoxDialog = ({ open, onClose, onConfirm, storages, selectedBox }) => {
  const [targetStorage, setTargetStorage] = useState('');

  const handleConfirm = () => {
    onConfirm(targetStorage);
    setTargetStorage('');
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{fontSize: '1.25rem'}}>
        체리 박스 이동
      </DialogTitle>
      <DialogContent dividers>
        <Typography variant="body1" gutterBottom>
          체리 박스({selectedBox})를 이동합니다. 이동할 체리 저장소를 선택하고 확인 버튼을 클릭하세요.
        </Typography>
        <FormControl fullWidth>
          <Select
            value={targetStorage}
            size={'small'}
            onChange={(e) => setTargetStorage(e.target.value)}
            displayEmpty
          >
            <MenuItem value="" disabled>이동할 저장소 선택</MenuItem>
            {storages.map((storage, index) => (
              <MenuItem key={index} value={storage}>{storage}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">취소</Button>
        <Button onClick={handleConfirm} color="primary" disabled={!targetStorage}>확인</Button>
      </DialogActions>
    </Dialog>
  );
};

export default MoveBoxDialog;