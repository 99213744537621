// src/utils/scriptUtils.js

export const parseScript = (script) => {
  const scriptLines = script.split('\n').filter(line => line.trim() !== '');
  const timeScripts = [];
  let _timeScript = null;

  scriptLines.forEach(line => {
    if (/^([0-9]?[0-9]):[0-5][0-9]$/.test(line)) {
      if (_timeScript) {
        timeScripts.push(_timeScript);
      }
      _timeScript = { time: line, script: '' };
    } else if (_timeScript) {
      _timeScript.script += (_timeScript.script ? ' ' : '') + line;
    }
  });

  if (_timeScript) {
    timeScripts.push(_timeScript);
  }

  return timeScripts;
};

export const findMatchedScript = (farm, time, startTime, endTime) => {
  const [minutes, seconds] = time.split(':').map(Number);
  const targetStartTime = minutes * 60 + seconds - startTime;
  const targetEndTime = minutes * 60 + seconds + endTime;
  const scriptElements = [];

  for (const ts of farm.timeScripts) {
    const [tsMinutes, tsSeconds] = ts.time.split(':').map(Number);
    const scriptTime = tsMinutes * 60 + tsSeconds;
    if (scriptTime >= targetStartTime && scriptTime < targetEndTime) {
      scriptElements.push({ time: ts.time, script: ts.script });
    }
  }

  if (scriptElements.length === 0) {
    scriptElements.push({ time: null, script: '해당 시구간에 일치하는 스크립트가 없습니다.' });
  }

  return scriptElements;
};