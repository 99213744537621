import React, {useState} from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Typography,
  IconButton
} from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";

const AddCherryBoxDialog = ({open, onClose, onSave}) => {
  const [boxName, setBoxName] = useState('');

  const handleSave = () => {
    onSave(boxName);
    setBoxName('');
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{fontSize: '1.25rem'}}>
        체리 박스 추가
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon/>
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Typography>체리를 담을 체리 박스를 합니다. 체리 박스명을 입력하고 저장 버튼을 누르세요.</Typography>
        <TextField
          autoFocus
          margin="normal"
          type="text"
          size={'small'}
          fullWidth
          value={boxName}
          onChange={(e) => setBoxName(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onClose}>취소</Button>
        <Button color="primary" onClick={handleSave} style={{marginLeft: '10px'}}>생성</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddCherryBoxDialog;