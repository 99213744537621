import React from 'react';
import { AppBar, Typography, Box } from "@mui/material";

const Header = () => {
  return (
    <AppBar position="static">
      <Box sx={{ bgcolor: 'primary.main', p: 1, textAlign: 'center', display: { xs: 'none', sm: 'block' } }}>
        <Typography variant="body1" color="white">나만의 체리! 원하는 영상의 핵심 구간만 빠르게 찾아보고, 더 스마트하게 즐기세요!</Typography>
      </Box>
      <Box sx={{
        textAlign: 'center',
        bgcolor: '#f5f5f5',
        p: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: '32px'
      }}>
        <img src="/cherry.png" alt="Cherry Logo"
             style={{height: '60px', verticalAlign: 'middle'}}/>
        <Typography
          variant="h1"
          color="red"
          sx={{fontFamily: 'cursive', fontStyle: 'italic', fontSize: '3.5rem'}}
        >Cherry</Typography>
      </Box>
    </AppBar>
  );
};

export default Header;