import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import LZString from 'lz-string';
dayjs.extend(duration);

export const isValidCherry = (cherry) => {
  const urlPattern = /^https:\/\/www\.youtube\.com\/watch\?v=[\w-]+$/;
  const timePattern = /^\d+:[0-5]\d$/;

  if (!cherry.id) {
    console.log('Invalid cherry: Missing id');
    return false;
  }
  if (!cherry.storageName) {
    console.log('Invalid cherry: Missing storageName');
    return false;
  }
  if (!cherry.boxName) {
    console.log('Invalid cherry: Missing boxName');
    return false;
  }
  if (!cherry.farm) {
    console.log('Invalid cherry: Missing farm');
    return false;
  }
  if (!cherry.farm.name) {
    console.log('Invalid cherry: Missing farm name');
    return false;
  }
  if (!urlPattern.test(cherry.farm.url)) {
    console.log('Invalid cherry: Invalid farm URL');
    return false;
  }
  if (!timePattern.test(cherry.time)) {
    console.log('Invalid cherry: Invalid time format');
    return false;
  }
  if (!Number.isInteger(cherry.startTime) || cherry.startTime < 0) {
    console.log('Invalid cherry: Invalid startTime');
    return false;
  }
  if (!Number.isInteger(cherry.endTime) || cherry.endTime < 0) {
    console.log('Invalid cherry: Invalid endTime');
    return false;
  }
  if (!cherry.matchedScript) {
    console.log('Invalid cherry: Missing matchedScript');
    return false;
  }

  return true;
};

export const getClipUrl = (cherry, startOffset, endOffset) => {
  const videoId = () => {
    const url = new URL(cherry.farm.url);
    const urlParams = new URLSearchParams(url.search);
    const vid = urlParams.get('v');
    if (!vid) {
      alert('유효한 YouTube URL을 입력하세요.');
      return;
    }
    return vid;
  }

  const [minutes, seconds] = cherry.time.split(':').map(Number);
  const pointTime = minutes * 60 + seconds;
  const clipStart = Math.max(pointTime - startOffset, 0);
  const clipEnd = pointTime + endOffset;

  return `https://www.youtube.com/embed/${videoId()}?start=${clipStart}&end=${clipEnd}&autoplay=1&controls=1`;
};

export const getTimeDuration = (baseTime, offset) => {
  const [minutes, seconds] = baseTime.split(':').map(Number);
  const baseDuration = dayjs.duration({ minutes, seconds });
  return baseDuration.add(offset, 'seconds');
}

export const getScriptsInRange = (timeScripts, start, end) => {
  return timeScripts
    .filter(({ time }) => {
      const [minutes, seconds] = time.split(':').map(Number);
      const totalSeconds = minutes * 60 + seconds;
      return totalSeconds >= start && totalSeconds <= end;
    })
    .map(({ script }) => script)
    .join(' ');
};

export const getCherryData = (cherry, startOffset, endOffset) => {
  return {
    id: cherry.id,
    name: cherry.name,
    url: getClipUrl(cherry, startOffset, endOffset),
    farmName: cherry.farm.name,
    time: cherry.time,
    startTime: getTimeDuration(cherry.time, -startOffset).format('mm:ss'),
    endTime: getTimeDuration(cherry.time, endOffset).format('mm:ss'),
    note: cherry.note,
    scripts: getScriptsInRange(cherry.farm.timeScripts, getTimeDuration(cherry.time, -startOffset).asSeconds(), getTimeDuration(cherry.time, endOffset).asSeconds()),
  };
};

export const compressedCherryData = (cherryData) => {
  const jsonString = JSON.stringify(cherryData);
  const compressed = LZString.compressToBase64(jsonString);
  return compressed;
};

export const decompressedCherryData = (compressedData) => {
  const jsonString = LZString.decompressFromBase64(compressedData);
  const cherryData = JSON.parse(jsonString);
  return cherryData;
};
