import React, { useState, useEffect, useRef } from 'react';
import {
  Typography,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Chip,
  IconButton
} from '@mui/material';
import AddScriptDialog from '../components/farm/AddScriptDialog';
import FarmDAO from '../dao/FarmDAO';
import DeleteIcon from "@mui/icons-material/Delete";
import AddCherryFarmGuide from '../components/farm/AddCherryFarmGuide';

const CherryFarm = () => {
  const [farms, setFarms] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [newFarm, setNewFarm] = useState({name: '', url: '', script: ''});
  const addFarmButtonRef = useRef(null);

  useEffect(() => {
    const storedFarms = FarmDAO.getFarms();
    setFarms(storedFarms);
  }, []);

  const handleDelete = (id) => {
    FarmDAO.deleteFarm(id);
    const updatedFarms = farms.filter(farm => farm.id !== id);
    setFarms(updatedFarms);
  };

  const handleOpenModal = () => {
    setNewFarm({name: '', url: '', script: ''});
    setModalIsOpen(true);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
  };

  const handleChange = (e) => {
    const {name, value} = e.target;
    setNewFarm({...newFarm, [name]: value});
  };

  const handleSave = () => {
    try {
      FarmDAO.addFarm(newFarm.name, newFarm.url, newFarm.script);
      const updatedFarms = FarmDAO.getFarms();
      setFarms(updatedFarms);
      handleCloseModal();
    } catch (error) {
      alert(error.message);
    }
  };

  const triggerAddFarmClick = () => {
    if (addFarmButtonRef.current) {
      addFarmButtonRef.current.click();
    }
  };

  return (
    <Box sx={{pt: 2}}>
      <Button ref={addFarmButtonRef} variant="contained" color="primary" style={{marginBottom: '10px'}}
              onClick={handleOpenModal}>+Add Farm</Button>
      {farms.length === 0 ? (
        <AddCherryFarmGuide triggerAddFarmClick={triggerAddFarmClick}/>
      ) : (
        <TableContainer component={Paper} style={{overflowX: 'auto'}}>
          <Table sx={{tableLayout: 'fixed'}}>
            <TableHead style={{position: 'sticky', top: 0, zIndex: modalIsOpen ? 1300 : 1}}>
              <TableRow style={{backgroundColor: '#F9F9F9'}}>
                <TableCell
                  style={{width: '50%', fontWeight: 'bold', textAlign: 'center', padding: '8px 8px'}}>이름</TableCell>
                <TableCell style={{width: '50%', fontWeight: 'bold', textAlign: 'center', padding: '8px 8px'}}>상세
                  정보</TableCell>
                <TableCell
                  style={{width: '60px', fontWeight: 'bold', textAlign: 'center', padding: '8px 8px'}}>액션</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {farms.map((farm) => (
                <TableRow key={farm.id}
                          style={{backgroundColor: farm.id % 2 === 0 ? '#fafafa' : '#ffffff', height: '36px'}}>
                  <TableCell title={farm.name} style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    width: '40%',
                    padding: '4px 8px',
                    fontSize: '0.875rem',
                    whiteSpace: 'nowrap'
                  }}>{farm.name}</TableCell>
                  <TableCell style={{
                    padding: '4px 8px',
                    fontSize: '0.875rem',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap'
                  }}>
                    <Box>
                      <ul style={{listStyleType: 'none', paddingLeft: 0}}>
                        <li style={{display: 'flex', alignItems: 'center', marginBottom: '6px'}}>
                          <Chip label="Script" sx={{
                            width: '56px',
                            borderRadius: '2px',
                            height: 'auto',
                            padding: '0px 0px',
                            mr: 1,
                            fontSize: '0.75rem',
                            fontWeight: 'bold'
                          }}/>
                          <Typography variant="body2"
                                      sx={{color: 'text.secondary'}}><Box component="span"
                                                                          sx={{fontWeight: 'bold'}}>{farm.timeScripts[0].time}</Box> {farm.timeScripts[0].script}
                          </Typography>
                        </li>
                        <li style={{display: 'flex', alignItems: 'center', marginBottom: '6px'}}>
                          <Chip label="Link" sx={{
                            width: '56px',
                            borderRadius: '2px',
                            height: 'auto',
                            padding: '0px 0px',
                            mr: 1,
                            fontSize: '0.75rem',
                            fontWeight: 'bold'
                          }}/>
                          <Typography variant="body2"><a href={farm.url} target="_blank"
                                                         rel="noopener noreferrer">{farm.url}</a></Typography>
                        </li>
                      </ul>
                    </Box>
                  </TableCell>


                  {/*<TableCell title={farm.url} style={{ overflow: 'hidden', textOverflow: 'ellipsis', width: '25%', padding: '4px 8px', fontSize: '0.875rem', whiteSpace: 'nowrap' }}>*/}
                  {/*  <a href={farm.url} target="_blank" rel="noopener noreferrer">{farm.url}</a>*/}
                  {/*</TableCell>*/}
                  {/*<TableCell style={{ width: '35%', padding: '4px 8px', fontSize: '0.875rem', whiteSpace: 'nowrap' }}>*/}
                  {/*  {farm.timeScripts.length > 0 && (*/}
                  {/*    <Box display="flex" alignItems="center" mb={1}>*/}
                  {/*      <Chip label={farm.timeScripts[0].time} size="small" color="primary" style={{ marginRight: '8px' }} />*/}
                  {/*      <Typography variant="body2" noWrap>{farm.timeScripts[0].script}</Typography>*/}
                  {/*    </Box>*/}
                  {/*  )}*/}
                  {/*</TableCell>*/}
                  <TableCell style={{width: '6px', textAlign: 'center', padding: '4px 8px', fontSize: '0.875rem'}}>
                    <IconButton color="red" onClick={() => handleDelete(farm.id)} sx={{margin: '0 0px'}}>
                      <DeleteIcon/>
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <AddScriptDialog
        modalIsOpen={modalIsOpen}
        handleCloseModal={handleCloseModal}
        newScript={newFarm}
        handleChange={handleChange}
        handleSave={handleSave}
      />
    </Box>
  );
};

export default CherryFarm;