import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  Typography,
  Button,
  Select,
  Box,
  TextField,
  IconButton
} from '@mui/material';
import BoxDAO from '../../dao/BoxDAO';
import CherryDAO from '../../dao/CherryDAO';
import CloseIcon from "@mui/icons-material/Close";

const CherryPickDialog = ({ open, handleClose, clipData, startTime, endTime }) => {
  const [selectedBox, setSelectedBox] = useState('');
  const [cherryBoxList, setCherryBoxList] = useState([]);
  const [cherryName, setCherryName] = useState('');
  const [note, setNote] = useState('');
  const [selectedCherry, setSelectedCherry] = useState({
    farm: '',
    time: '',
    matchedScript: '',
    startTime: '',
    endTime: ''
  });

  useEffect(() => {
    const storedCherryBoxes = BoxDAO.getBoxes();
    const formattedCherryBoxes = storedCherryBoxes.map(box => `${box.storage} - ${box.name}`);
    setCherryBoxList(formattedCherryBoxes);

    if (open) {
      setSelectedBox('');
      setCherryName('');
      setNote('');
    }

    if (clipData) {
      setSelectedCherry({
        farm: clipData.farm,
        time: clipData.time,
        matchedScript: clipData.matchedScript,
        startTime: startTime,
        endTime: endTime
      });
    }
  }, [open, clipData, startTime, endTime]);

  const saveCherry = (cherry) => {
    CherryDAO.addCherry(cherry.storageName, cherry.boxName, cherry.farm, cherry.time, cherry.matchedScript, cherry.startTime, cherry.endTime, cherry.name, cherry.note);
    setSelectedCherry(cherry);
  };

  const handleSave = () => {
    const [storageName, boxName] = selectedBox.split(' - ');
    const cherry = {
      storageName,
      boxName,
      farm: clipData.farm,
      time: clipData.time,
      matchedScript: clipData.matchedScript,
      startTime,
      endTime,
      name: cherryName,
      note
    };

    saveCherry(cherry);
    handleClose();
  };

  return (
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle sx={{ fontSize: '1.25rem' }}>
          체리픽
          <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Box mt={2} display="flex" alignItems="center" sx={{ ml: 3 }}>
            <Typography variant="body2" sx={{ width: '50px', whiteSpace: 'nowrap' }}>상세 정보:</Typography>
            <Typography variant="body2" sx={{ ml: 5, flex: 1 }}>
              <strong>{selectedCherry.farm.name}</strong><br />
              기준 시간 {selectedCherry.time} 이전 {selectedCherry.startTime}초 부터 이후 {selectedCherry.endTime}초 까지
            </Typography>
          </Box>
          <Box mt={2} display="flex" alignItems="center" sx={{ ml: 3 }}>
            <Typography variant="body2" sx={{ width: '50px', whiteSpace: 'nowrap' }}>체리 박스*:</Typography>
            <FormControl fullWidth sx={{ ml: 5, width: '50%' }}>
              <Select
                value={selectedBox}
                size={'small'}
                onChange={(e) => setSelectedBox(e.target.value)}
                disabled={cherryBoxList.length === 0}
              >
                {cherryBoxList.map((box, index) => (
                  <MenuItem key={index} value={box}>{box}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box mt={2} display="flex" alignItems="center" sx={{ ml: 3 }}>
            <Typography variant="body2" sx={{ width: '50px', whiteSpace: 'nowrap' }}>체리 이름:</Typography>
            <TextField
                fullWidth
                sx={{ ml: 5, width: '50%' }}
                value={cherryName}
                onChange={(e) => setCherryName(e.target.value)}
                size="small"
            />
          </Box>
          <Box mt={2} display="flex" alignItems="center" sx={{ ml: 3 }}>
            <Typography variant="body2" sx={{ width: '50px', whiteSpace: 'nowrap' }}>노트:</Typography>
            <TextField
                fullWidth
                multiline
                rows={5}
                sx={{ ml: 5, width: '80%' }}
                value={note}
                onChange={(e) => setNote(e.target.value)}
                size="small"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">취소</Button>
          <Button onClick={handleSave} color="primary" disabled={cherryBoxList.length === 0}>확인</Button>
        </DialogActions>
      </Dialog>
  );
};

export default CherryPickDialog;
