import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';

const DeleteCherryBoxDialog = ({ open, onClose, onConfirm, selectedBox }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{fontSize: '1.25rem'}}>
        Delete Cherry Box
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          체리 박스("{selectedBox}")를 삭제하면 보관중인 체리를 모두 잃을 수 있습니다. 그래도 삭제하시겠습니까?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onClose}>취소</Button>
        <Button color="primary" onClick={onConfirm} style={{marginLeft: '10px'}}>확인</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteCherryBoxDialog;