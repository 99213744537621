import React from 'react';
import { Box, Typography, Button, Grid } from '@mui/material';

const MoveCherryFarmGuide = () => {
  return (
    <Box
      sx={{
        backgroundColor: '#fff3cd',
        padding: '20px',
        borderRadius: '8px',
        textAlign: 'center',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        margin: '20px 0'
      }}
    >
      <Typography variant="h5" gutterBottom>
        👨‍🌾 FARM 등록하기: 체리 PICK의 첫 단계
      </Typography>
      <Typography variant="body2" paragraph>
        체리 PICK을 시작하려면 먼저 유튜브 동영상을 FARM에 등록해야 합니다.
      </Typography>
      <Grid container justifyContent="center">
        <Grid item xs={12} md={3}>
          <Box
            sx={{
              backgroundColor: '#ffffff',
              padding: '10px',
              borderRadius: '8px',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
              textAlign: 'center',
              margin: '10px',
              height: '120px'
            }}
          >
            <Box
              sx={{
                backgroundColor: '#555',
                color: '#ffffff',
                borderRadius: '50%',
                width: '30px',
                height: '30px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '0 auto 10px',
                fontSize: '1.4rem',
                fontWeight: 'bolder',
                fontFamily: 'cursive',
                fontStyle: 'italic'
              }}
            >
              1
            </Box>
            <Typography variant="body1">'FARM 메뉴로 이동'을 클릭하여 이동하세요.</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box
            sx={{
              backgroundColor: '#ffffff',
              padding: '10px',
              borderRadius: '8px',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
              textAlign: 'center',
              margin: '10px',
              height: '120px'
            }}
          >
            <Box
              sx={{
                backgroundColor: '#555',
                color: '#ffffff',
                borderRadius: '50%',
                width: '30px',
                height: '30px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '0 auto 10px',
                fontSize: '1.4rem',
                fontWeight: 'bolder',
                fontFamily: 'cursive',
                fontStyle: 'italic'
              }}
            >
              2
            </Box>
            <Typography variant="body1">FARM 페이지에서 '+ADD FARM' 버튼을 찾아 클릭하세요.</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box
            sx={{
              backgroundColor: '#ffffff',
              padding: '10px',
              borderRadius: '8px',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
              textAlign: 'center',
              margin: '10px',
              height: '120px'
            }}
          >
            <Box
              sx={{
                backgroundColor: '#555',
                color: '#ffffff',
                borderRadius: '50%',
                width: '30px',
                height: '30px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '0 auto 10px',
                fontSize: '1.4rem',
                fontWeight: 'bolder',
                fontFamily: 'cursive',
                fontStyle: 'italic'
              }}
            >
              3
            </Box>
            <Typography variant="body1">원하는 유튜브 동영상의 URL을 입력하여 FARM에 추가하세요.</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box
            sx={{
              backgroundColor: '#ffffff',
              padding: '10px',
              borderRadius: '8px',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
              textAlign: 'center',
              margin: '10px',
              height: '120px'
            }}
          >
            <Box
              sx={{
                backgroundColor: '#555',
                color: '#ffffff',
                borderRadius: '50%',
                width: '30px',
                height: '30px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '0 auto 10px',
                fontSize: '1.4rem',
                fontWeight: 'bolder',
                fontFamily: 'cursive',
                fontStyle: 'italic'
              }}
            >
              4
            </Box>
            <Typography variant="body1">이제 등록된 동영상에서 체리를 PICK할 준비가 되었습니다!</Typography>
          </Box>
        </Grid>
      </Grid>
      <Button
        variant="contained"
        color="primary"
        href="/farm"
        sx={{ mt: 2 }}
      >
        🚜 FARM 메뉴로 이동
      </Button>
    </Box>
  );
};

export default MoveCherryFarmGuide;