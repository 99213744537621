// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { Box, Container } from '@mui/material';
import Header from './components/Header';
import GNB from './components/GNB';
import CherryFarm from './pages/CherryFarm';
import CherryPick from './pages/CherryPick';
import CherryStorage from './pages/CherryStorage';
import CherryPlay from './pages/CherryPlay';
import './styles/global.css';

const App = () => {
  const location = useLocation();
  const isCherryRoute = location.pathname === '/cherry';

  return (
    <div>
      {!isCherryRoute && <Header />}
      {!isCherryRoute && <GNB />}
      <Container fixed>
        <Box mt={2}>
          <Routes>
            <Route path="/" element={<CherryPick />} />
            <Route path="/farm" element={<CherryFarm />} />
            <Route path="/storage" element={<CherryStorage />} />
            <Route path="/cherry" element={<CherryPlay />} />
          </Routes>
        </Box>
      </Container>
    </div>
  );
};

const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;
