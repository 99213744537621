// src/components/GNB.js
import { AppBar, Toolbar, Button, Container } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';

const GNB = () => {
  const location = useLocation();

  return (
    <AppBar position="static" sx={{ bgcolor: '#f5f5f5' }} elevation={0}>
      <Container maxWidth="lg">
        <Toolbar sx={{ borderBottom: '1px solid #000000', paddingBottom: '0px' }}>
          <Button
            color="inherit"
            component={Link}
            to="/"
            sx={{
              color: location.pathname === '/' ? 'primary.main' : '#000000',
              fontSize: '1.05rem',
              paddingBottom: '0px',
              borderBottom: location.pathname === '/' ? '2px solid primary.main' : 'none'
            }}
          >
            🍒 Pick
          </Button>
          <Button
            color="inherit"
            component={Link}
            to="/storage"
            sx={{
              color: location.pathname === '/storage' ? 'primary.main' : '#000000',
              fontSize: '1.05rem',
              paddingBottom: '0px',
              borderBottom: location.pathname === '/storage' ? '2px solid primary.main' : 'none'
            }}
          >
            📦 Storage
          </Button>
          <Button
            color="inherit"
            component={Link}
            to="/farm"
            sx={{
              color: location.pathname === '/farm' ? 'primary.main' : '#000000',
              fontSize: '1.05rem',
              paddingBottom: '0px',
              borderBottom: location.pathname === '/farm' ? '2px solid primary.main' : 'none'
            }}
          >
            🚜 Farm
          </Button>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default GNB;