import React, { useState } from 'react';
import {Card, Typography, Box, Button} from '@mui/material';
import YouTubeIcon from "@mui/icons-material/YouTube";

const CherryDataPlayer = ({clipUrl}) => {
  const [currentUrl, setCurrentUrl] = useState(decodeURIComponent(clipUrl));

  const handleHomeClick = () => {
    const origin = window.location.origin;
    window.location.href = origin;
  };

  const handlePlayClick = () => {
    setCurrentUrl('');
    setTimeout(() => {
       setCurrentUrl(decodeURIComponent(clipUrl));
    }, 100);
  };

  const handleViewFullVideoClick = () => {
    if (clipUrl) {
      const decodedUrl = decodeURIComponent(clipUrl);
      const videoId = decodedUrl.split('/').pop().split('?')[0];
      const youtubeUrl = `https://www.youtube.com/embed/${videoId}?autoplay=1`;
      setCurrentUrl('');
      setTimeout(() => {
        setCurrentUrl(decodeURIComponent(youtubeUrl));
      }, 100);
    }
  };

  return (
    <Box sx={{ width: '100%', position: 'relative' }}>
      <Box sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '8px'
      }}>
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer'
        }} onClick={handleHomeClick}>
          <Box component="img" src="./cherry.png" alt="Cherry" sx={{ width: 32, height: 32, marginRight: 1 }} />
          <Typography variant="h6" color="red" sx={{fontFamily: 'cursive', fontStyle: 'italic', fontSize: '1.5rem'}}>Cherry</Typography>
        </Box>

        <Box alignItems="center" sx={{
          display: 'flex',
          alignItems: 'center'
        }}>
          <Button variant="outlined" color="secondary" onClick={handlePlayClick}
                  sx={{height: '30px', marginRight: '10px'}}>
            <YouTubeIcon sx={{marginRight: '5px'}}/>
            구간 재생
          </Button>
          <Button variant="outlined" color="secondary" onClick={handleViewFullVideoClick}
                  sx={{height: '30px', marginRight: '10px'}}>
            <YouTubeIcon sx={{marginRight: '5px'}}/>
            전체 재생
          </Button>
        </Box>
      </Box>
      <Card sx={{
        backgroundColor: 'white',
        borderRadius: '8px',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
        width: '100%'
      }}>

        {currentUrl && (
          <Box flexGrow={1} sx={{
            width: '100%',
            height: 0,
            position: 'relative',
            paddingBottom: '56.25%', // 16:9 aspect ratio
            borderRadius: '8px',
            overflow: 'hidden',
            '& iframe': {
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%'
            }
          }}>
            <iframe
              title="Cherry Player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              src={currentUrl}
            ></iframe>
          </Box>
        )}
      </Card>
    </Box>
  );
};

export default CherryDataPlayer;
