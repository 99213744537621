import { v4 as uuidv4 } from 'uuid';

class CherryDAO {
  static validateCherryName(name) {
    const minLength = 4;
    const maxLength = 32;
    const regex = /^[\p{L}\p{N} _-]+$/u;

    if (name.length < minLength || name.length > maxLength) {
      throw new Error(`Cherry name must be between ${minLength} and ${maxLength} characters.`);
    }

    if (!regex.test(name)) {
      throw new Error('Cherry name can only contain letters, numbers, spaces, underscores, and hyphens.');
    }
  }

  static getCherries() {
    const cherries = JSON.parse(localStorage.getItem('cherries')) || [];
    return cherries.map(cherry => ({
      ...cherry,
      name: cherry.name || cherry.farm.name
    }));
  }

  static getCherriesByStorageAndBox(storageName, boxName) {
    const cherries = this.getCherries();
    return cherries.filter(cherry =>
      (!storageName || cherry.storageName === storageName) &&
      (!boxName || cherry.boxName === boxName)
    ).map(cherry => ({
      ...cherry,
      name: cherry.name || cherry.farm.name
    }));
  }

  static getCherryById(cherryId) {
    const cherries = this.getCherries();
    return cherries.find(cherry => cherry.id === cherryId) || null;
  }

  static addCherry(storageName, boxName, farm, time, matchedScript, startTime, endTime, name, note) {
    if (name) {
      this.validateCherryName(name);
    }

    const cherries = this.getCherries();
    const cherry = {
      id: uuidv4(),
      storageName,
      boxName,
      farm,
      time,
      matchedScript,
      startTime,
      endTime,
      name: name || farm.name,
      note: note || ''
    };
    cherries.push(cherry);
    localStorage.setItem('cherries', JSON.stringify(cherries));
  }

  static updateCherryName(id, newName) {
    this.validateCherryName(newName); // Validate the new name
    const cherries = this.getCherries();
    const cherryIndex = cherries.findIndex(cherry => cherry.id === id);
    if (cherryIndex !== -1) {
      cherries[cherryIndex].name = newName;
      localStorage.setItem('cherries', JSON.stringify(cherries));
    }
  }

  static updateCherryNoteById(id, newNote) {
    const cherries = this.getCherries();
    const cherryIndex = cherries.findIndex(cherry => cherry.id === id);
    if (cherryIndex !== -1) {
      cherries[cherryIndex].note = newNote;
      localStorage.setItem('cherries', JSON.stringify(cherries));
    } else {
      throw new Error('Cherry not found');
    }
  }

  static deleteCherry(id) {
    const cherries = this.getCherries();
    const updatedCherries = cherries.filter(cherry => cherry.id !== id);
    localStorage.setItem('cherries', JSON.stringify(updatedCherries));
  }

  static deleteCherryByIndex(index) {
    const cherries = this.getCherries();
    if (index >= 0 && index < cherries.length) {
      cherries.splice(index, 1);
      localStorage.setItem('cherries', JSON.stringify(cherries));
    }
  }
}

export default CherryDAO;
