const blogPrompt = {
  blogStyle: {
    font: "Noto Serif KR",
    noteInstructions: "체리 노트의 내용은 검토하고 블로그 스타일을 반영해서 교정해줘. 다만, 내용의 의미가 변하지 않도록 주의해줘. 노트가 없다면 체리 스크립트를 참고해서 의미를 간략하게 만들어줘. 블로그 스타일을 지켜서 작성해.",
    scriptInstructions: "체리 스크립트는 완성된 문장이 아니야. 완성된 문장 형태만 남겨줘. 입력된 스크립트 원문을 유지하되 의미 전달이 어려운 부분만 단어 수준에서 교정해줘"
  },
  blogTemplate: `
<section id="cherry-box">
    <h2 style="color: #0073e6; padding-bottom: 10px; text-align: center; font-family: 'Noto Serif KR';">{{cherryData.name}}</h2>
      <article style="margin-bottom: 30px; font-family: 'Noto Serif KR';">
        <div style="margin-top: 15px;">
          <p style="text-align: center;">{{cherryData.note}}</p>
        </div>
        <div style="margin-top: 15px; text-align: center;">
          <iframe id="{{cherryData.id}}" width="560" height="315" src="{{cherryData.url}}" title="{{cherryData.farmName}}" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div style="margin-top: 15px;">
          <p style="text-align: center; font-size: 0.85em;">🍒 <strong>{{cherryData.farmName}}</strong></p>
          <p style="text-align: center; font-size: 0.85em;">⏰ {{cherryData.startTime}} ~ {{cherryData.endTime}}
            <button style="margin-left: 10px;" onclick="(function(){var iframe = document.getElementById('{{cherryData.id}}'); var src = iframe.src; iframe.src = src;})()">다시 보기</button>
          </p>
        </div>
        <div style="margin-top: 10px;">
          <p style="text-align: center; font-size: 0.85em; margin-top: 15px; color: #777"><span style="font-size: 2em;">❝</span><br>{{cherryData.scripts}}<br><span style="font-size: 2em;">❞</span></p>
        </div>
      </article>
</section>
`
};

export default blogPrompt;
