import React, {useState, useEffect} from 'react';
import {Box, useMediaQuery} from '@mui/material';
import DeleteCherryStorageDialog from '../components/storage/DeleteCherryStorageDialog';
import AddCherryStorageDialog from '../components/storage/AddCherryStorageDialog';
import DeleteCherryBoxDialog from '../components/storage/DeleteCherryBoxDialog';
import AddCherryBoxDialog from '../components/storage/AddCherryBoxDialog';
import MoveBoxDialog from '../components/storage/MoveBoxDialog';
import StorageSelect from '../components/storage/StorageSelect';
import BoxSelect from '../components/storage/BoxSelect';
import CherryList from '../components/storage/CherryList';
import CherryPlayer from "../components/common/CherryPlayer";
import StorageDAO from '../dao/StorageDAO';
import BoxDAO from '../dao/BoxDAO';
import CherryDAO from '../dao/CherryDAO';
import AddCherryStorageGuide from '../components/storage/AddCherryStorageGuide';

const CherryStorage = () => {
  const [storages, setStorages] = useState([]);
  const [selectedStorage, setSelectedStorage] = useState(localStorage.getItem('selectedStorage') || '');
  const [openDeleteStorageDialog, setOpenDeleteStorageDialog] = useState(false);
  const [openAddStorageDialog, setOpenAddStorageDialog] = useState(false);

  const [boxes, setBoxes] = useState([]);
  const [storageBoxes, setStorageBoxes] = useState([]);
  const [selectedBox, setSelectedBox] = useState(localStorage.getItem('selectedBox') || '');
  const [openDeleteBoxDialog, setOpenDeleteBoxDialog] = useState(false);
  const [openAddBoxDialog, setOpenAddBoxDialog] = useState(false);
  const [openMoveBoxDialog, setOpenMoveBoxDialog] = useState(false);

  const [cherries, setCherries] = useState([]);
  const [selectedCherry, setSelectedCherry] = useState(null);

  const [showNote, setShowNote] = useState(true);

  const isSmallOrTabletScreen = useMediaQuery(theme => theme.breakpoints.down('md'));

  useEffect(() => {
    const storedStorages = StorageDAO.getStorages();
    const sortedStorages = storedStorages.sort();
    setStorages(sortedStorages);
    if (sortedStorages.length > 0 && !selectedStorage) {
      setSelectedStorage(sortedStorages[0]);
    }
  }, []);

  useEffect(() => {
    const storedBoxes = BoxDAO.getBoxes();
    const sortedBoxes = storedBoxes.sort((a, b) => (a.name || '').localeCompare(b.name || ''));
    setBoxes(sortedBoxes);
  }, []);

  useEffect(() => {
    if (selectedStorage) {
      localStorage.setItem('selectedStorage', selectedStorage);
      const filtered = boxes.filter(box => box.storage === selectedStorage);
      setStorageBoxes(filtered);
      if (filtered.length > 0 && !selectedBox) {
        setSelectedBox(filtered[0].name);
      }
    }
  }, [selectedStorage, boxes]);

  useEffect(() => {
    if (selectedBox) {
      localStorage.setItem('selectedBox', selectedBox);
      const boxCherries = CherryDAO.getCherriesByStorageAndBox(selectedStorage, selectedBox);
      setCherries(boxCherries);
    }
  }, [selectedBox, selectedStorage]);

  const handleAddStorageConfirm = (newStorage) => {
    StorageDAO.addStorage(newStorage);
    const newStorages = StorageDAO.getStorages().sort();
    setStorages(newStorages);
    setSelectedStorage(newStorage);
    setOpenAddStorageDialog(false);
  };

  const handleDeleteStorageConfirm = () => {
    StorageDAO.deleteStorage(selectedStorage, true);
    const newStorages = StorageDAO.getStorages().sort();
    setStorages(newStorages);
    if (newStorages.length > 0) {
      setSelectedStorage(newStorages[0]);
      const newBoxes = BoxDAO.getBoxes().sort((a, b) => (a.name || '').localeCompare(b.name || ''));
      setBoxes(newBoxes);
      const filtered = newBoxes.filter(box => box.storage === newStorages[0]);
      setStorageBoxes(filtered);
      if (filtered.length > 0) {
        setSelectedBox(filtered[0].name);
      } else {
        setSelectedBox('');
      }
    } else {
      setSelectedStorage('');
      setBoxes([]);
      setStorageBoxes([]);
      setSelectedBox('');
    }
    setOpenDeleteStorageDialog(false);
  };

  const handleAddBoxConfirm = (newBoxName) => {
    BoxDAO.addBox(newBoxName, selectedStorage);
    const newBoxes = BoxDAO.getBoxes().sort((a, b) => (a.name || '').localeCompare(b.name || ''));
    setBoxes(newBoxes);
    setSelectedBox(newBoxName);
    setOpenAddBoxDialog(false);
  };

  const handleDeleteBoxConfirm = () => {
    BoxDAO.deleteBox(selectedBox, true);
    const newBoxes = BoxDAO.getBoxes().sort((a, b) => (a.name || '').localeCompare(b.name || ''));
    setBoxes(newBoxes);
    const filtered = newBoxes.filter(box => box.storage === selectedStorage);
    setStorageBoxes(filtered);
    if (filtered.length > 0) {
      setSelectedBox(filtered[0].name);
    } else {
      setSelectedBox('');
    }
    setOpenDeleteBoxDialog(false);
  };

  const handleMoveBoxConfirm = (targetStorage) => {
    const updatedBoxes = boxes.map(box =>
      box.name === selectedBox ? {...box, storage: targetStorage} : box
    );
    setBoxes(updatedBoxes);
    setOpenMoveBoxDialog(false);
  };

  const handlePlay = (cherry) => {
    setSelectedCherry(cherry);
  };

  const handleCherryUpdate = () => {
    const boxCherries = CherryDAO.getCherriesByStorageAndBox(selectedStorage, selectedBox);
    setCherries(boxCherries);
  };

  const refreshStoragePage = () => {
    const storedStorages = StorageDAO.getStorages();
    const sortedStorages = storedStorages.sort();
    setStorages(sortedStorages);

    const storedBoxes = BoxDAO.getBoxes();
    const sortedBoxes = storedBoxes.sort((a, b) => (a.name || '').localeCompare(b.name || ''));
    setBoxes(sortedBoxes);

    if (selectedStorage) {
      const filtered = sortedBoxes.filter(box => box.storage === selectedStorage);
      setStorageBoxes(filtered);
      if (filtered.length > 0 && !selectedBox) {
        setSelectedBox(filtered[0].name);
      }
    }

    if (selectedBox) {
      const boxCherries = CherryDAO.getCherriesByStorageAndBox(selectedStorage, selectedBox);
      setCherries(boxCherries);
    }
  };

  return (
    <Box sx={{pt: 2}}>
      {isSmallOrTabletScreen ? (
        <>
          <StorageSelect
            storages={storages}
            selectedStorage={selectedStorage}
            setSelectedStorage={setSelectedStorage}
            setOpenAddStorageDialog={setOpenAddStorageDialog}
            setOpenDeleteStorageDialog={setOpenDeleteStorageDialog}
          />
          <BoxSelect
            boxes={storageBoxes}
            selectedBox={selectedBox}
            setSelectedBox={setSelectedBox}
            setOpenAddBoxDialog={setOpenAddBoxDialog}
            setOpenDeleteBoxDialog={setOpenDeleteBoxDialog}
            setOpenMoveBoxDialog={setOpenMoveBoxDialog}
            selectedStorage={selectedStorage}
            refreshStoragePage={refreshStoragePage}
          />
        </>
      ) : (
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          width: '100%',
          justifyContent: 'center'
        }}>
          <StorageSelect
            storages={storages}
            selectedStorage={selectedStorage}
            setSelectedStorage={setSelectedStorage}
            setOpenAddStorageDialog={setOpenAddStorageDialog}
            setOpenDeleteStorageDialog={setOpenDeleteStorageDialog}

          />
          <BoxSelect
            boxes={storageBoxes}
            selectedBox={selectedBox}
            setSelectedBox={setSelectedBox}
            setOpenAddBoxDialog={setOpenAddBoxDialog}
            setOpenDeleteBoxDialog={setOpenDeleteBoxDialog}
            setOpenMoveBoxDialog={setOpenMoveBoxDialog}
            selectedStorage={selectedStorage}
            refreshStoragePage={refreshStoragePage}
            sx={{marginBottom: 0}}
          />
        </Box>
      )}

      {(storages.length === 0 || storageBoxes.length === 0 || cherries.length === 0) && (
        <AddCherryStorageGuide
          storages={storages}
          boxes={storageBoxes}
          cherries={cherries}
          onAddStorage={() => setOpenAddStorageDialog(true)}
          onAddBox={() => setOpenAddBoxDialog(true)}
        />
      )}

      <Box mt={2}/>
      <CherryList cherries={cherries} onPlay={handlePlay} onCherryUpdate={handleCherryUpdate}/>
      <Box mt={2}/>

      {selectedCherry && (
        <CherryPlayer
          cherry={selectedCherry}
          onCherryUpdate={handleCherryUpdate}
          showNote={showNote}
          sx={{textAlign: isSmallOrTabletScreen ? 'left' : 'center'}}
        />
      )}

      <DeleteCherryStorageDialog
        open={openDeleteStorageDialog}
        onClose={() => setOpenDeleteStorageDialog(false)}
        onConfirm={handleDeleteStorageConfirm}
        selectedStorage={selectedStorage}
      />

      <AddCherryStorageDialog
        open={openAddStorageDialog}
        onClose={() => setOpenAddStorageDialog(false)}
        onSave={handleAddStorageConfirm}
      />

      <DeleteCherryBoxDialog
        open={openDeleteBoxDialog}
        onClose={() => setOpenDeleteBoxDialog(false)}
        onConfirm={handleDeleteBoxConfirm}
        selectedBox={selectedBox}
      />

      <AddCherryBoxDialog
        open={openAddBoxDialog}
        onClose={() => setOpenAddBoxDialog(false)}
        onSave={handleAddBoxConfirm}
      />

      <MoveBoxDialog
        open={openMoveBoxDialog}
        onClose={() => setOpenMoveBoxDialog(false)}
        onConfirm={handleMoveBoxConfirm}
        storages={storages}
        selectedBox={selectedBox}
      />
    </Box>
  );
};

export default CherryStorage;
