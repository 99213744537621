import React from 'react';
import CherryDataPlayer from '../components/common/CherryDataPlayer';
import { useLocation } from 'react-router-dom';

const CherryPlay = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const cherryUrl = queryParams.get('cherryUrl');

  return <CherryDataPlayer clipUrl={cherryUrl} />;
};

export default CherryPlay;
