import React from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const AddScriptDialog = ({modalIsOpen, handleCloseModal, newScript, handleChange, handleSave}) => {
  return (
    <Dialog
      open={modalIsOpen}
      onClose={handleCloseModal}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle sx={{fontSize: '1.25rem'}}>
        체리 농장 추가
        <IconButton
          aria-label="close"
          onClick={handleCloseModal}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon/>
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Box sx={{display: 'flex', alignItems: 'center', marginBottom: '4px'}}>
          <Typography variant="body2" sx={{minWidth: '100px'}}>Youtube URL</Typography>
          <TextField
            name="url"
            value={newScript.url}
            onChange={handleChange}
            size={"small"}
            fullWidth
            margin="normal"
          />
        </Box>
        <Box sx={{display: 'flex', alignItems: 'center', marginBottom: '4px'}}>
          <Typography variant="body2" sx={{minWidth: '100px'}}>이름</Typography>
          <TextField
            name="name"
            value={newScript.name}
            onChange={handleChange}
            size={"small"}
            fullWidth
            margin="normal"
          />
        </Box>
        <Box sx={{display: 'flex', alignItems: 'top', marginBottom: '4px'}}>
          <Typography variant="body2" sx={{minWidth: '100px', marginTop: '20px'}}>스크립트</Typography>
          <TextField
            name="script"
            value={newScript.script}
            onChange={handleChange}
            fullWidth
            multiline
            rows={8}
            style={{overflow: 'auto'}}
            margin="normal"
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={handleCloseModal}>취소</Button>
        <Button color="primary" onClick={handleSave} style={{marginLeft: '10px'}}>저장</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddScriptDialog;