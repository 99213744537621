import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Box,
  Typography,
  Chip,
  TextField
} from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CherryDAO from '../../dao/CherryDAO';
import YouTubeIcon from "@mui/icons-material/YouTube";
import DeleteIcon from "@mui/icons-material/Delete";

const CherryList = ({cherries, onPlay, onCherryUpdate}) => {
  const [editingCherryId, setEditingCherryId] = useState(null);
  const [editingCherryName, setEditingCherryName] = useState('');

  const handleDelete = (id) => {
    CherryDAO.deleteCherry(id);
    onCherryUpdate();
  };

  const handlePlay = (cherry) => {
    onPlay(cherry);
  };

  const handleCherryNameChange = (event) => {
    setEditingCherryName(event.target.value);
  };

  const handleNameDoubleClick = (cherry) => {
    setEditingCherryId(cherry.id);
    setEditingCherryName(cherry.name);
  };

  const handleKeyDown = (event, cherry) => {
    if (event.key === 'Enter') {
      handleNameBlur(cherry);
    }
  };

  const handleNameBlur = (cherry) => {
    try {
      CherryDAO.updateCherryName(cherry.id, editingCherryName);
      setEditingCherryId(null);
      onCherryUpdate();
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <>
      <ToastContainer />
      {cherries.length > 0 && (
        <TableContainer component={Paper} sx={{maxHeight: 360, overflowY: 'auto'}}>
          <Table sx={{tableLayout: 'fixed'}}>
            <TableHead style={{position: 'sticky', top: 0, zIndex: 1}}>
              <TableRow style={{backgroundColor: '#F9F9F9'}}>
                <TableCell style={{width: '30%', fontWeight: 'bold', textAlign: 'center', padding: '8px 8px'}}>체리</TableCell>
                <TableCell style={{width: '70%', fontWeight: 'bold', textAlign: 'center', padding: '8px 8px'}}>상세 정보</TableCell>
                <TableCell style={{width: '80px', fontWeight: 'bold', textAlign: 'center', padding: '8px 8px'}}>액션</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cherries.map((cherry) => (
                <TableRow key={cherry.id}>
                  <TableCell
                    style={{padding: '4px 8px', fontSize: '0.875rem', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}
                    title="Double-click to edit"
                  >
                    {editingCherryId === cherry.id ? (
                      <TextField
                        value={editingCherryName}
                        onChange={handleCherryNameChange}
                        onBlur={() => handleNameBlur(cherry)}
                        onKeyDown={(event) => handleKeyDown(event, cherry)}
                        autoFocus
                        size="small"
                        fullWidth
                      />
                    ) : (
                      <span onDoubleClick={() => handleNameDoubleClick(cherry)}>{cherry.name}</span>
                    )}
                  </TableCell>
                  <TableCell style={{padding: '4px 8px', fontSize: '0.875rem'}}>
                    <Box>
                      <Typography variant="body2">{cherry.farm.name}</Typography>
                      <ul style={{listStyleType: 'none', paddingLeft: 20}}>
                        <li style={{display: 'flex', alignItems: 'center', marginBottom: '6px'}}>
                          <Chip label="Time" sx={{
                            width: '56px',
                            borderRadius: '2px',
                            height: 'auto',
                            padding: '0px 0px',
                            mr: 1,
                            fontSize: '0.75rem',
                            fontWeight: 'bold'
                          }}/>
                          <Typography variant="body2" sx={{color: 'text.secondary'}}>
                            기준 시간 <Box component="span" sx={{fontWeight: 'bold'}}>{cherry.time}</Box> 이전 <Box
                            component="span" sx={{fontWeight: 'bold'}}>{cherry.startTime}</Box>초 부터 이후 <Box
                            component="span" sx={{fontWeight: 'bold'}}>{cherry.endTime}</Box>초 까지
                          </Typography>
                        </li>
                        <li style={{display: 'flex', alignItems: 'center', marginBottom: '6px'}}>
                          <Chip label="Script" sx={{
                            width: '56px',
                            borderRadius: '2px',
                            height: 'auto',
                            padding: '0px 0px',
                            mr: 1,
                            fontSize: '0.75rem',
                            fontWeight: 'bold'
                          }}/>
                          <Typography variant="body2" sx={{color: 'text.secondary'}}><Box component="span" sx={{fontWeight: 'bold'}}>{cherry.time}</Box> {cherry.matchedScript}</Typography>
                        </li>
                        <li style={{display: 'flex', alignItems: 'center', marginBottom: '6px'}}>
                          <Chip label="Link" sx={{
                            width: '56px',
                            borderRadius: '2px',
                            height: 'auto',
                            padding: '0px 0px',
                            mr: 1,
                            fontSize: '0.75rem',
                            fontWeight: 'bold'
                          }}/>
                          <Typography variant="body2"><a href={cherry.farm.url} target="_blank"
                                                         rel="noopener noreferrer">{cherry.farm.url}</a></Typography>
                        </li>
                      </ul>
                    </Box>
                  </TableCell>
                  <TableCell style={{padding: '4px 4px', fontSize: '0.875rem', textAlign: 'center'}}>
                    <IconButton color="red" onClick={() => handlePlay(cherry)}>
                      <YouTubeIcon/>
                    </IconButton>
                    <IconButton color="red" onClick={() => handleDelete(cherry.id)}>
                      <DeleteIcon/>
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default CherryList;