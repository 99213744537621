import React, { useRef, useState } from 'react';
import { Box, FormControl, Select, MenuItem, Typography, Button, IconButton, Tooltip, useMediaQuery } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/Upload';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import CherryDAO from '../../dao/CherryDAO';
import StorageDAO from '../../dao/StorageDAO';
import BoxDAO from '../../dao/BoxDAO';
import { isValidCherry } from '../../utils/cherryUtils';

const BoxSelect = ({ boxes, selectedBox, setSelectedBox, setOpenAddBoxDialog, setOpenDeleteBoxDialog, setOpenMoveBoxDialog, selectedStorage, refreshStoragePage }) => {
  const fileInputRef = useRef(null);
  const [fileInputKey, setFileInputKey] = useState(Date.now());
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const handleAddBox = () => {
    setOpenAddBoxDialog(true);
  };

  const handleDeleteBox = () => {
    setOpenDeleteBoxDialog(true);
  };

  const handleMoveBox = () => {
    setOpenMoveBoxDialog(true);
  };

  const handleDownload = async () => {
    if (!selectedBox) {
      alert('박스를 선택하세요.');
      return;
    }

    const cherries = CherryDAO.getCherriesByStorageAndBox(selectedStorage, selectedBox);
    const jsonData = JSON.stringify(cherries, null, 2);

    const zip = new JSZip();
    zip.file(`${selectedBox}.json`, jsonData);

    const content = await zip.generateAsync({ type: 'blob' });
    saveAs(content, `${selectedBox}.zip`);
  };

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && selectedFile.size <= 5 * 1024 * 1024) { // 5MB 제한
      await handleUpload(selectedFile);
      setFileInputKey(Date.now());
    } else {
      alert('유효하지 않은 파일입니다. 파일 크기는 5MB를 초과할 수 없습니다.');
    }
  };

  const handleUpload = async (file) => {
    if (!file) {
      alert('파일을 선택하세요.');
      return;
    }

    const zip = new JSZip();
    const content = await zip.loadAsync(file);
    const fileName = Object.keys(content.files)[0];
    const fileData = await content.file(fileName).async('string');
    const cherries = JSON.parse(fileData);

    if (!Array.isArray(cherries)) {
      alert('잘못된 파일 형식입니다.');
      return;
    }

    if (!Array.isArray(cherries)) {
      alert('잘못된 파일 형식입니다.');
      return;
    }

    const validCherries = cherries.filter(isValidCherry);
    if (validCherries.length !== cherries.length) {
      alert('유효하지 않은 데이터가 포함되어 있습니다.');
      return;
    }

    const existingCherries = CherryDAO.getCherries();
    const newCherries = validCherries.filter(
      (newCherry) => !existingCherries.some((existingCherry) => existingCherry.id === newCherry.id)
    );

    // Check and create storage and box if they do not exist
    newCherries.forEach(cherry => {
      const storages = StorageDAO.getStorages();
      if (!storages.includes(cherry.storageName)) {
        StorageDAO.addStorage(cherry.storageName);
      }
      const boxes = BoxDAO.getBoxes();
      if (!boxes.some(box => box.name === cherry.boxName)) {
        BoxDAO.addBox(cherry.boxName, cherry.storageName);
      }
    });

    const updatedCherries = [...existingCherries, ...newCherries];
    localStorage.setItem('cherries', JSON.stringify(updatedCherries));
    alert('업로드가 완료되었습니다.');

    // Refresh the STORAGE page
    refreshStoragePage();
  };

  return (
    <Box sx={{ display: 'flex', flexGrow: 1, alignItems: 'center', marginBottom: 1, justifyContent: 'space-between' }}>
      <FormControl sx={{ minWidth: 300, mr: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="body1" sx={{ mr: 1, width: isSmallScreen ? '60px' : 'auto' }}>Box: </Typography>
          <Select
            labelId=""
            value={selectedBox}
            size="small"
            onChange={(e) => setSelectedBox(e.target.value)}
            sx={{ flexGrow: 1, backgroundColor: 'white' }}
            label={isSmallScreen ? "Box" : ""}
          >
            {boxes.map((box, index) => (
              <MenuItem key={index} value={box.name}>{box.name}</MenuItem>
            ))}
          </Select>
        </Box>
      </FormControl>
      {!isSmallScreen && (
        <>
          <Button variant="contained" color="primary" onClick={handleAddBox} sx={{ mr: 1, width: 60, whiteSpace: 'nowrap' }}>+ 추가</Button>
          <Button variant="contained" color="primary" onClick={handleMoveBox} sx={{ mr: 1, width: 60, whiteSpace: 'nowrap' }} disabled={!selectedBox}>이동</Button>
          <Button variant="contained" color="secondary" onClick={handleDeleteBox} sx={{ width: 60, whiteSpace: 'nowrap' }} disabled={!selectedBox}>- 삭제</Button>
        </>
      )}
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', flexGrow: 1 }}>
        <Tooltip title="체리 박스 다운로드">
          <IconButton color="primary" onClick={handleDownload} disabled={!selectedBox}>
            <DownloadIcon />
          </IconButton>
        </Tooltip>
        <input key={fileInputKey} type="file" onChange={handleFileChange} style={{ display: 'none' }} ref={fileInputRef} />
        <Tooltip title="체리 박스 업로드">
          <IconButton color="primary" onClick={() => fileInputRef.current.click()}>
            <UploadIcon />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default BoxSelect;