// src/dao/FarmDAO.js
import { parseScript } from '../utils/scriptUtils';
import { v4 as uuidv4 } from 'uuid';

class FarmDAO {
  static getFarms() {
    return JSON.parse(localStorage.getItem('cherryFarms')) || [];
  }

  static getFarm(id) {
    const farms = this.getFarms();
    return farms.find(farm => farm.id === id);
  }

  static addFarm(name, url, script) {
    const farms = this.getFarms();

    // Check for duplicate farm name or URL
    if (farms.some(farm => farm.url === url)) {
      throw new Error('Farm URL already exists');
    }

    const timeScripts = parseScript(script);
    const scriptsString = timeScripts.map(ts => ts.script).join(' ');

    const farm = {
      id: uuidv4(),
      name: name.trim(),
      url: url.trim(),
      timeScripts: timeScripts,
      scripts: scriptsString
    };

    farms.push(farm);
    localStorage.setItem('cherryFarms', JSON.stringify(farms));
  }

  static deleteFarm(id) {
    const farms = this.getFarms();
    const updatedFarms = farms.filter(farm => farm.id !== id);
    localStorage.setItem('cherryFarms', JSON.stringify(updatedFarms));
  }
}

export default FarmDAO;