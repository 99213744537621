import React from 'react';
import { Box, FormControl, Select, MenuItem, Typography, Button, useMediaQuery } from '@mui/material';

const StorageSelect = ({ storages, selectedStorage, setSelectedStorage, setOpenAddStorageDialog, setOpenDeleteStorageDialog }) => {
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const handleAddStorage = () => {
    setOpenAddStorageDialog(true);
  };

  const handleDeleteStorage = () => {
    setOpenDeleteStorageDialog(true);
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1, marginRight: 2 }}>
      <FormControl sx={{ minWidth: 300, mr: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="body1" sx={{ mr: 1, width: isSmallScreen ? '60px' : 'auto' }}>Storage: </Typography>
          <Select
            labelId="storage-select-label"
            value={selectedStorage}
            size="small"
            onChange={(e) => setSelectedStorage(e.target.value)}
            sx={{ flexGrow: 1, backgroundColor: 'white'  }}
            label={isSmallScreen ? "Storage" : ""}
          >
            {storages.map((storage, index) => (
              <MenuItem key={index} value={storage}>{storage}</MenuItem>
            ))}
          </Select>
        </Box>
      </FormControl>
      {!isSmallScreen && (
        <>
          <Button variant="contained" color="primary" onClick={handleAddStorage} sx={{ mr: 1, width: 60, whiteSpace: 'nowrap' }}>+ 추가</Button>
          <Button variant="contained" color="secondary" onClick={handleDeleteStorage} sx={{ width: 60, whiteSpace: 'nowrap' }} disabled={!selectedStorage}>- 삭제</Button>
        </>
      )}
    </Box>
  );
};

export default StorageSelect;